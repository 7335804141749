import {Deserializable} from './deserializable.model';
import {GifMediaImages} from './gif-media-images.model';

export class GifMedia implements Deserializable {
  id: number;
  title: string;
  rating: string;
  images: GifMediaImages;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.deserializeImages(input.images);
    return this;
  }

  private deserializeImages(input) {
    this.images = new GifMediaImages().deserialize(input);
  }
}
