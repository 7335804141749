import {Injectable} from '@angular/core';
import {ApiRouteService} from './api-route.service';
import {HttpClient} from '@angular/common/http';
import {PaginatedForms} from '../models/paginated-forms.model';
import {Form} from '../models/form.model';
import {environment} from '../../environments/environment';
import {buildUrl} from 'build-url';

@Injectable({
  providedIn: 'root'
})

export class UserFormsService {
  user: number;

  constructor(private apiURLs: ApiRouteService,
              private http: HttpClient) {
    this.user = +localStorage.getItem('uid');
  }

  public indexForms(user: number, filter = null) {
    return this.http.get<PaginatedForms>(this.apiURLs.indexUserForms(user, filter));
  }

  public viewForm(form: number) {
    return this.http.get<Form>(this.apiURLs.viewForm(form));
  }

  public updateForm(form: number, attributes: any) {
    return this.http.put<Form>(this.apiURLs.viewForm(form), attributes);
  }

  public createForm(user: number, form: object) {
    return this.http.post<Form>(this.apiURLs.indexUserForms(user, form), form);
  }

  public deleteForm(form: number) {
    return this.http.delete(this.apiURLs.viewForm(form));
  }

  public defaultContactForm() {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `forms/2`,
      hash: '',
      queryParams: null
    }));
  }
}
