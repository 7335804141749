import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {buildUrl} from 'build-url';
import {User} from '../models/user.model';
import {environment} from '../../environments/environment';
import {TaskModel} from '../models/task.model';
import {PaginatedTasks} from '../models/paginated-tasks.model';

@Injectable({
  providedIn: 'root'
})

export class TaskService {
  private apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = environment.apiURL;
  }

  public indexForUser(owner: User, params: any) {
    return this.http.get<PaginatedTasks>(buildUrl(this.apiURL, {
      path: `users/${owner.id}/tasks`,
      hash: '',
      queryParams: params
    }));
  }

  public linkForUser(owner: User) {
    return this.http.get(buildUrl(this.apiURL, {
      path: `users/${owner.id}/tasks/calendar/link`,
      hash: '',
      queryParams: null
    }));
  }

  public create(owner: User, attributes: any) {
    return this.http.post<TaskModel>(buildUrl(this.apiURL, {
      path: `users/${owner.id}/tasks`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public update(taskId: number, attributes: any) {
    return this.http.put<TaskModel>(buildUrl(this.apiURL, {
      path: `tasks/${taskId}`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public delete(taskId: number) {
    return this.http.delete(buildUrl(this.apiURL, {
      path: `tasks/${taskId}`,
      hash: '',
      queryParams: null
    }), {});
  }

  public complete(taskId: number) {
    return this.http.post<TaskModel>(buildUrl(this.apiURL, {
      path: `tasks/${taskId}/complete`,
      hash: '',
      queryParams: null
    }), null);
  }
}
