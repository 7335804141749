import {Deserializable} from './deserializable.model';
import {Reporting} from './reporting.model';
import {Recipient} from './recipient.model';

export class TemplatedEmail implements Deserializable {
  id: number;
  templated_message_id: number;
  subject: string;
  description: string;
  greeting_with_first_name: string;
  greeting_without_first_name: string;
  body: string;
  text: string;
  closing: string;
  ps_line: string;
  signature_id: number;
  status: string;
  reason: string;
  send_type: string;
  send_at_local: any;
  send_at: string;
  sending_at: string;
  sent_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  recipients: Recipient[];
  exclusions: Recipient[];
  reporting: Reporting | null;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeReporting(input.reporting);
    return this;
  }

  private deserializeReporting(input) {
    this.reporting = new Reporting().deserialize(input);
  }
}
