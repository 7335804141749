import {Deserializable} from './deserializable.model';
import {Recipient} from './recipient.model';
import {Reporting} from './reporting.model';

export class TextChannel implements Deserializable {
  id: number;
  owner_type: string;
  owner_id: number;
  text_number_id: number;
  name: string;
  description: string;
  greeting_with_first_name: string;
  greeting_without_first_name: string;
  body: string;
  attachments: string[];
  status: string;
  reason: string;
  send_type: string;
  send_at_local: any;
  send_at: string;
  sending_at: string;
  sent_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  recipients: Recipient[];
  exclusions: Recipient[];
  reporting: Reporting | null;
  unread_threads: number;
  recipients_count: number;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeReporting(input.reporting);
    this.deserializeRecipients(input.recipients);
    return this;
  }

  private deserializeReporting(input) {
    this.reporting = new Reporting().deserialize(input);
  }

  private deserializeRecipients(recipients) {
    this.recipients = [];
    if (recipients == null) {
      return;
    }

    for (const recipient of recipients) {
      this.recipients.push(new Recipient().deserialize(recipient));
    }
  }
}
