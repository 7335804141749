import {Deserializable} from './deserializable.model';

export class GifMediaImage implements Deserializable {
  height: string;
  width: string;
  size: string;
  url: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
