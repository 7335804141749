import {Injectable} from '@angular/core';
import {ApiRouteService} from './api-route.service';
import {HttpClient} from '@angular/common/http';
import {BlastEmail} from '../models/blast-email.model';
import {PaginatedAudits} from '../models/paginated-audits.model';
import {AuthService} from './auth.service';
import {User} from '../models/user.model';
import {buildUrl} from 'build-url';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BlastEmailsService {
  user: User;

  constructor(private apiURLs: ApiRouteService, private http: HttpClient) {
    this.user = AuthService.getCurrentUser();
  }

  public viewBlastEmail(blast: number) {
    return this.http.get<BlastEmail>(this.apiURLs.viewBlastEmail(blast));
  }

  public editBlastEmail(blast: number, attributes) {
    return this.http.put<BlastEmail>(this.apiURLs.editBlastEmail(blast), attributes);
  }

  public deleteBlastEmail(blast: number) {
    return this.http.delete<any>(this.apiURLs.viewBlastEmail(blast));
  }

  public blastAudits(blast: number, filter: object = null) {
    return this.http.get<PaginatedAudits>(this.apiURLs.indexBlastEmailAudits(blast, filter));
  }

  public recipients(email: number, filter: object = null) {
    return this.http.get<any>(this.apiURLs.indexBlastEmailRecipients(email, filter));
  }

  public exportRecipients(email: BlastEmail, attributes) {
    return this.http.post(buildUrl(environment.apiURL, {
      path: `blast_emails/${email.id}/recipients/export`,
      hash: '',
      queryParams: null
    }), attributes);
  }
}
