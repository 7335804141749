import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {buildUrl} from 'build-url';

@Injectable({
  providedIn: 'root'
})
export class GifMediaService {

  constructor(private http: HttpClient) {
  }

  public trending(provider: string, params: any) {
    return this.http.get<any[]>(buildUrl(environment.apiURL, {
      path: `gif_media/${provider}/trending`,
      hash: '',
      queryParams: params
    }));
  }

  public search(provider: string, params: any) {
    return this.http.get<any[]>(buildUrl(environment.apiURL, {
      path: `gif_media/${provider}/search`,
      hash: '',
      queryParams: params
    }));
  }
}
