import {PaginatedResponse} from './paginated-response.model';
import {Field} from './field.model';

export class PaginatedFields extends PaginatedResponse {
  data: Field[];

  deserializeData(fields: object[]) {
    this.data = [];
    for (const field of fields) {
      this.data.push(new Field().deserialize(field));
    }
  }
}
