import {PaginatedResponse} from './paginated-response.model';
import {TaskModel} from './task.model';

export class PaginatedTasks extends PaginatedResponse {
  data: TaskModel[];

  deserializeData(values: object[]) {
    this.data = [];
    for (const value of values) {
      this.data.push(new TaskModel().deserialize(value));
    }
  }
}
