import {Deserializable} from './deserializable.model';
import {Reporting} from './reporting.model';
import {Recipient} from './recipient.model';
import {User} from './user.model';
import {Signature} from './signature.model';

export class BlastEmail implements Deserializable {
  id: number;
  subject: string;
  description: string;
  greeting_with_first_name: string;
  greeting_without_first_name: string;
  body: string;
  text: string;
  closing: string;
  ps_line: string;
  signature_id: number;
  status: string;
  reason: string;
  send_type: string;
  send_at_local: any;
  send_at: string;
  sending_at: string;
  sent_at: string;
  individual_send = false;
  is_split_send_parent = false;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  recipients: Recipient[];
  exclusions: Recipient[];
  reporting: Reporting | null;
  owner: User;
  signature: Signature;
  source: string;
  unread_threads: number;
  recipients_count: number;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeReporting(input.reporting);
    this.deserializeRecipients(input.recipients);
    this.deserializeOwner(input.owner);
    if (input.signature) {
      this.deserializeSignature(input.signature);
    }
    return this;
  }

  private deserializeReporting(input) {
    this.reporting = new Reporting().deserialize(input);
  }

  private deserializeOwner(input) {
    this.owner = new User().deserialize(input);
  }

  private deserializeSignature(input) {
    this.signature = new Signature().deserialize(input);
  }

  private deserializeRecipients(recipients) {
    this.recipients = [];
    if (recipients == null) {
      return;
    }

    for (const recipient of recipients) {
      this.recipients.push(new Recipient().deserialize(recipient));
    }
  }
}
