import {Deserializable} from './deserializable.model';
import {GifMediaImage} from './gif-media-image.model';

export class GifMediaImages implements Deserializable {
  original: GifMediaImage;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.deserializeOriginal(input.original);
    return this;
  }

  private deserializeOriginal(input) {
    this.original = new GifMediaImage().deserialize(input);
  }
}
