import {Deserializable} from './deserializable.model';
import {Media} from './media.model';
import {Recipient} from './recipient.model';
import {Reporting} from './reporting.model';

export class VoicemailDrop implements Deserializable {
  id: number;
  provider: string;
  media_id: number;
  callback_number: number;
  name: string;
  description: string;
  status: string;
  reason: string;
  send_type: string;
  send_at_local: any;
  send_at: string;
  sending_at: string;
  sent_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  media: Media;
  recipients: Recipient[];
  reporting: Reporting | null;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeRecipients(input.recipients);
    this.deserializeReporting(input.reporting);
    return this;
  }

  private deserializeReporting(input) {
    this.reporting = new Reporting().deserialize(input);
  }

  private deserializeRecipients(recipients) {
    this.recipients = [];
    if (recipients == null) {
      return;
    }

    for (const recipient of recipients) {
      this.recipients.push(new Recipient().deserialize(recipient));
    }
  }
}
