import {Deserializable} from './deserializable.model';
import {Contact} from './contact.model';
import {User} from './user.model';
import {TeamOwner} from './team-owner.model';

export class TaskModel implements Deserializable {
  id: number;
  key: string;
  owner_type: number;
  owner_id: number;
  owner: User;
  name: string;
  description: string;
  due_date: string;
  due_time: string;
  subject_type: string;
  subject_id: string;
  subject: Contact;
  assignee_type: string;
  assignee_id: number;
  assignee_email: string;
  assignee_first_name: string;
  assignee_last_name: string;
  assignee: {
    email: string,
    first_name: string,
    last_name: string,
    full_name: string,
  };
  is_editable: boolean;
  completed_at: string;
  created_at: string;
  updated_at: string;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeOwner(this.owner);
    if (this.subject) {
      this.deserializeSubject(this.subject);
    }
    return this;
  }

  private deserializeOwner(input) {
    this.owner = new User().deserialize(input);
  }

  private deserializeSubject(input) {
    this.subject = new Contact().deserialize(input);
  }
}
