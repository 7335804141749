import {PaginatedResponse} from './paginated-response.model';
import {BlastEmail} from './blast-email.model';

export class PaginatedBlastEmails extends PaginatedResponse {
  data: BlastEmail[];

  deserializeData(data: object[]) {
    this.data = [];
    for (const blastEmail of data) {
      this.data.push(new BlastEmail().deserialize(blastEmail));
    }
  }

  find(id) {
    const index = this.data.findIndex(x => x.id === id);

    return this.data[index];
  }

  next(current) {
    const index = this.data.findIndex(x => x.id === current);

    if (index === -1) {
      return this.data[0];
    }

    const next = index + 1;

    if (this.data[next] == null) {
      return current;
    }

    return this.data[next];
  }

  prev(current) {
    const index = this.data.findIndex(x => x.id === current);

    if (index === -1) {
      return this.data[0];
    }

    const prev = index - 1;

    if (this.data[prev] == null) {
      return this.data[0];
    }

    return this.data[prev];
  }
}
