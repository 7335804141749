import {Deserializable} from './deserializable.model';

export class TextMessage implements Deserializable {
  id: number;
  text_channel_id: number;
  thread_id: string;
  sender_type: string;
  sender_id: number;
  receiver_type: string;
  receiver_id: number;
  body: string;
  attachments: string[];
  status: string;
  reason: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
