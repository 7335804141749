import {PaginatedResponse} from './paginated-response.model';
import {TextMessage} from './text-message.model';

export class PaginatedTextMessages extends PaginatedResponse {
  data: TextMessage[];

  deserializeData(input: object[]) {
    this.data = [];
    for (const value of input) {
      this.data.push(new TextMessage().deserialize(value));
    }
  }
}
